.nav {
  height: 15vh;
  width: 100vw;
  overflow: hidden;
//   border: 3px solid blue;
//   background-color: cyan;
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(to bottom right, rgb(0, 255, 170), rgb(0, 158, 158));

  h2 {
    margin: auto 16px;
    font-size: 32px;
    font-weight: bold;
    color: white;
    // border: 2px solid black;
  }

  .links{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    margin-right: 48px;

    .link {
      height: 50%;
      margin: auto 16px;
    }

    .btn {
      margin: auto 16px;
      height: 100%;
      width: 100%;
      border-radius: 3px;
      text-decoration: none;
      color: white;
      font-size: 20px;
      font-weight: bold;
      background: transparent;
      padding: 4px;
  
      &:hover {
        background-color: white;
        color: rgb(3, 182, 182);
        text-decoration: underline;
      }
    }
  }

}
