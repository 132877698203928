.hello {
  width: 45vw;
  height: 45vh;
  border-radius: 5px;
  background-image: linear-gradient(
    to bottom right,
    rgb(0, 255, 170),
    rgb(0, 158, 158)
  );
  margin: 150px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  padding: 8px;

  h2 {
    font-size: 48px;
  }

  p {
    font-size: 28px;
  }

  button {
    text-decoration: none;
    color: white;
    font-size: 20px;
    font-weight: bold;
    background: transparent;
    padding: 4px;

    &:hover {
      background-color: white;
      color: rgb(3, 182, 182);
      text-decoration: underline;
    }
  }
}
