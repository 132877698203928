.dash {
  height: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .inputs {
    width: 30vw;
    height: auto;
    margin: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(0, 255, 170);
    border-radius: 5px;

    h2 {
      margin-bottom: 16px;
    }
  }

  .btn {
    margin: auto 16px;
    height: 100%;
    width: 30%;
    border-radius: 3px;
    text-decoration: none;
    color: white;
    font-size: 20px;
    font-weight: bold;
    border-color:  rgb(255, 170, 0);
    background: rgb(255, 170, 0);
    padding: 4px;

    &:hover {
      background-color: white;
      color:  rgb(255, 170, 0);
      text-decoration: underline;
    }
  }
}
